import { useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { Payments, Paywalls } from '@organicapps/organictypes';
import { usePaywallsStore, useUserStore } from '../store';
import ApiClients from '../api';
import { getSinglePayProduct } from '../helpers/getSinglePayProduct';
import { IPagesParams } from '../pages/Offer';
import { SEARCH_PARAMS } from '../constants/searchParams';
import { useLogger } from './useLogger';
import { useAnalytics } from './useAnalytics';
import { ApiError } from '../api/baseApi';

const DEFAULT_ERROR_MESSAGE = 'Error with payment. Please try again.';
const useContinuePayment = (): {
  error: string;
  isLoading: boolean;
  continuePay: (onSuccess: () => void, paymentSystemData: Payments.IPaymentSystemData) => void;
} => {
  const logger = useLogger();
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { logStartPurchase, logFinishPurchase, logPaymentError } = useAnalytics();
  const [searchParams] = useSearchParams();
  const { productName } = useParams<keyof IPagesParams>() as IPagesParams;

  const { email, appUserId, firstName, lastName } = useUserStore((state) => ({
    email: state.email,
    firstName: state.firstName,
    lastName: state.lastName,
    appUserId: state.appUserId,
  }));

  const { selectedPaywallList, paywallType } = usePaywallsStore((state) => ({
    selectedPaywallList: state.selectedPaywallList as Paywalls.IPaywallList,
    paywallType: state.settings.paywallType,
    paymentSystems: state.settings.paymentSystems,
  }));

  const continuePay = async (onSuccess: () => void, paymentSystemData: Payments.IPaymentSystemData) => {
    try {
      setIsLoading(true);
      const paymentsClient = ApiClients.getPaymentsClient();
      const product = getSinglePayProduct(paywallType, productName);
      // ToDo: maybe useless
      const payerId = searchParams.get(SEARCH_PARAMS.STRIPE_PAYER_ID);

      // because hook initializes earlier than selectedPaywallList
      const {
        price: { _id, intervalCount },
      } = selectedPaywallList;

      const payload: Payments.ISubscriptionPayload<unknown> | Payments.ISinglePayPayload<unknown> = {
        appUserId,
        email,
        description: `[${paymentSystemData.system}] ${product} Payment from ${email}`,
        firstName,
        lastName,
        priceId: _id,
        paywallList: selectedPaywallList,
        provider: paymentSystemData.system,
        paymentProvider: paymentSystemData.provider,
        source: { payerId },
      };

      logStartPurchase(paymentSystemData);

      if (intervalCount) {
        await paymentsClient.onSubscriptionPay(payload);
      } else {
        await paymentsClient.onSinglePay(payload);
      }

      logFinishPurchase(paymentSystemData);
      onSuccess();
    } catch (e) {
      let errorToShow = DEFAULT_ERROR_MESSAGE;
      let errorToSend = DEFAULT_ERROR_MESSAGE;

      if (e instanceof ApiError) {
        errorToShow = e?.message || errorToShow;
        errorToSend = e?.cause || errorToSend;
      }

      setError(errorToShow);
      logPaymentError(paymentSystemData, errorToSend);
      logger.logError(e as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return { error, isLoading, continuePay };
};

export default useContinuePayment;
